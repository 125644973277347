import axios from 'axios';
import * as endpoints from './endpoints';

export enum AliasType {
  burner = 'burner',
  group = 'group',
  personal = 'personal',
}

export enum MessageReportType {
  phishing = 'phishing',
  support = 'support',
}

export default {
  autoReplySettings: {
    get: async (): Promise<AutoReplySettings> => {
      return axios
        .get(endpoints.AUTO_REPLY_SETTINGS_ENDPOINT)
        .then((response) => response.data);
    },
    update: ({
      settings,
    }: {
      settings: AutoReplySettings;
    }): Promise<AutoReplySettings> => {
      return axios
        .put(endpoints.AUTO_REPLY_SETTINGS_ENDPOINT, settings)
        .then((response) => response.data);
    },
  },
  checkKeys: ({
    sender,
    addressList,
  }: {
    sender: string;
    addressList: string;
  }): Promise<CheckKeysResponseData> => {
    return axios
      .post(endpoints.CHECK_KEYS_ENDPOINT, { sender, addresses: addressList })
      .then((response) => response.data);
  },
  drafts: {
    create: ({ draft }: { draft: DraftRequestData }): Promise<Draft> => {
      return axios
        .post(endpoints.DRAFTS_ENDPOINT, draft)
        .then((response) => response.data);
    },
    createFromMessage: ({
      id,
      draft,
    }: {
      id: string;
      draft: DraftRequestData;
    }): Promise<Draft> => {
      return axios
        .post(endpoints.DRAFT_FROM_MESSAGE_ENDPOINT(id), draft)
        .then((response) => response.data);
    },
    get: ({ id }: { id: string }): Promise<Draft> => {
      return axios
        .get(endpoints.DRAFT_ENDPOINT(id))
        .then((response) => response.data);
    },
    delete: ({ id }: { id: string }) =>
      axios.delete(endpoints.DRAFT_ENDPOINT(id)),
    update: ({
      id,
      draft,
    }: {
      id: string;
      draft: DraftRequestData;
    }): Promise<Draft> => {
      return axios
        .patch(endpoints.DRAFT_ENDPOINT(id), draft)
        .then((response) => response.data);
    },
    send: ({
      draft_id,
      passwordProtectedMessage,
      signing,
    }: {
      draft_id: string;
      passwordProtectedMessage?: {
        password: string;
        message?: string;
        notificationLanguage: string;
      };
      signing?: {
        pgpPassphrase: string;
      };
    }): Promise<SendResponseData> => {
      return axios
        .post(endpoints.SEND_ENDPOINT, {
          id: draft_id,
          password_protected_message: passwordProtectedMessage,
          signing,
        })
        .then((response) => response.data);
    },
    attachFile: ({
      draftId,
      file,
      inline,
      onUploadProgress,
      onCancelToken,
    }: {
      draftId: string;
      file: File;
      inline: boolean;
      onUploadProgress: (progressEvent: any) => void;
      onCancelToken: Function;
    }): Promise<DraftUploadResponseData> => {
      const data = new FormData();
      data.append('file', file, file.name);
      data.append('inline', JSON.stringify(inline === true));

      const source = axios.CancelToken.source();

      onCancelToken(source);

      return axios
        .post(endpoints.ATTACHMENT_UPLOAD_ENDPOINT(draftId), data, {
          onUploadProgress,
          cancelToken: source.token,
        })
        .then((response) => response.data);
    },
    deleteFile: ({ draftId, id }: { draftId: string; id: string }) => {
      return axios
        .delete(endpoints.ATTACHMENT_ENDPOINT(draftId, id))
        .then((response) => response.data);
    },
  },
  trash: {
    empty: () => axios.post(endpoints.EMPTY_TRASH),
  },
  junk: {
    empty: () => axios.post(endpoints.EMPTY_JUNK),
  },
  feedback: {
    submit: ({
      type,
      message,
      receive_email,
      browser,
      attachments,
    }: FeedbackSubmitPayload) => {
      const formData = new FormData();
      formData.append('feedback_type', type);
      formData.append('feedback_message', message);
      formData.append('receive_email', JSON.stringify(receive_email));
      formData.append('browser', JSON.stringify(browser));
      if (attachments && attachments.length > 0) {
        attachments.forEach((attachment) => {
          formData.append('attachment', attachment);
        });
      }
      return axios.post(endpoints.REPORT_FEEDBACK_ENDPOINT, formData);
    },
  },
  folders: {
    all: ({
      noUpdateActivity,
    }: {
      noUpdateActivity: boolean;
    }): Promise<Folder[]> => {
      const headers = noUpdateActivity
        ? { 'X-StartMail-UpdateActivity': 'no' }
        : {};
      return axios
        .get(endpoints.FOLDERS_V2_ENDPOINT, { headers })
        .then((response) => response.data);
    },
    create: ({ folder }: { folder: FolderRequestData }): Promise<Folder> => {
      return axios
        .post(endpoints.FOLDERS_ENDPOINT, folder)
        .then((response) => response.data);
    },
    update: ({
      id,
      properties,
    }: {
      id: string;
      properties: Partial<FolderRequestData>;
    }) => {
      return axios
        .patch(endpoints.FOLDER_ENDPOINT(id), properties)
        .then((response) => response.data);
    },
    delete: ({ id }: { id: string }) => {
      return axios
        .delete(endpoints.FOLDER_ENDPOINT(id))
        .then((response) => response.data);
    },
  },
  messages: {
    get({
      folderId,
      query,
      offset,
      limit,
      order,
      filter,
      noUpdateActivity,
    }: {
      folderId: string;
      query: string;
      offset: number;
      limit: number;
      order: Object;
      filter: string[];
      noUpdateActivity: boolean;
    }): Promise<MessagesResponseData> {
      const params = Object.entries({
        folder_id: folderId,
        query,
        offset,
        limit,
        order: JSON.stringify(order),
        filter: filter.join(','),
      })
        .filter(([, val]) => val !== undefined)
        .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
      const headers = noUpdateActivity
        ? { 'X-StartMail-UpdateActivity': 'no' }
        : {};
      return axios
        .get(endpoints.MESSAGES_ENDPOINT, { params, headers })
        .then(({ data }) => data);
    },
    getOne({ id }: { id: string }): Promise<Message> {
      return axios.get(endpoints.MESSAGE_ENDPOINT(id)).then(({ data }) => data);
    },
    delete({ id }: { id: string }) {
      return axios.delete(endpoints.MESSAGE_DELETE_ENDPOINT(id));
    },
    bulk(body: {
      messages: string[];
      update: Partial<Message>;
    }): Promise<MessagesBulkResponseData> {
      return axios
        .post(endpoints.MESSAGES_BULK_ENDPOINT, body)
        .then(({ data }) => data);
    },
    decrypt({
      id,
      pgpPassphrase,
      rememberPgpPassphrase,
    }: {
      id: string;
      pgpPassphrase: string;
      rememberPgpPassphrase: boolean;
    }): Promise<Message> {
      return axios
        .post(endpoints.MESSAGE_DECRYPT_ENDPOINT(id), {
          pgpPassphrase,
          rememberPgpPassphrase,
        })
        .then(({ data }) => data);
    },
    async report({
      message,
      reportType,
    }: {
      message: string;
      reportType: MessageReportType;
    }): Promise<void> {
      await axios.post(endpoints.MESSAGES_REPORT_ENDPOINT, {
        message,
        report_type: reportType,
      });
    },
  },
  contacts: {
    find: async ({
      groupId,
      limit,
      offset,
      query,
    }: {
      groupId: string;
      limit: number;
      offset: number;
      query: string;
    }): Promise<ContactsResponseData> => {
      const { data } = await axios.get(endpoints.CONTACTS_ENDPOINT, {
        params: { limit, offset, group_id: groupId, query },
      });
      return data;
    },
    create: async ({ contact }: { contact: Contact }): Promise<Contact> => {
      const { data } = await axios.post(endpoints.CONTACTS_ENDPOINT, contact);
      return data;
    },
    update: async ({
      id,
      properties,
    }: {
      id: string;
      properties: Partial<Contact>;
    }): Promise<Contact> => {
      const { data } = await axios.patch(
        endpoints.CONTACT_ENDPOINT(id),
        properties
      );
      return data;
    },
    get: async ({ id }: { id: string }): Promise<Contact> => {
      const { data } = await axios.get(endpoints.CONTACT_ENDPOINT(id));
      return data;
    },
    delete: async ({ id }: { id: string }) => {
      const { data } = await axios.delete(endpoints.CONTACT_ENDPOINT(id));
      return data;
    },
    setFavoriteStatus: async ({
      id,
      favorite,
    }: {
      id: string;
      favorite: boolean;
    }): Promise<Contact> => {
      const { data } = await axios.patch(endpoints.CONTACT_ENDPOINT(id), {
        favorite,
      });
      return data;
    },
    import: async ({ files }: { files: File[] }): Promise<Contact[]> => {
      const formData = new FormData();
      if (files.length) {
        files.forEach((file) => {
          formData.append('files', file, file.name);
        });
      } else {
        formData.append('files', '');
      }
      const { data } = await axios.post(
        endpoints.CONTACTS_IMPORT_ENDPOINT,
        formData
      );
      return data;
    },
  },
  contactGroups: {
    all: async (): Promise<ContactGroup[]> => {
      const { data } = await axios.get(endpoints.CONTACT_GROUPS_ENDPOINT);
      return data;
    },
    create: async ({
      contactGroup,
    }: {
      contactGroup: ContactGroup;
    }): Promise<ContactGroup> => {
      const { data } = await axios.post(
        endpoints.CONTACT_GROUPS_ENDPOINT,
        contactGroup
      );
      return data;
    },
    update: async ({
      id,
      properties,
    }: {
      id: string;
      properties: Partial<ContactGroup>;
    }): Promise<ContactGroup> => {
      const { data } = await axios.patch(
        endpoints.CONTACT_GROUP_ENDPOINT(id),
        properties
      );
      return data;
    },
    delete: async ({ id }: { id: string }) => {
      const { data } = await axios.delete(endpoints.CONTACT_GROUP_ENDPOINT(id));
      return data;
    },
  },
  authentication: {
    get: (): Promise<AuthenticationResponseData> => {
      return axios
        .get(endpoints.AUTH_ENDPOINT)
        .then((response) => response.data);
    },
    login: ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }): Promise<AuthenticationResponseData> => {
      return axios
        .post(endpoints.LOGIN_ENDPOINT, { email, password })
        .then((response) => response.data);
    },
    terminate: ({
      password,
      comment,
    }: {
      password: string;
      comment: string;
    }) => {
      return axios
        .post(endpoints.TERMINATE_USER_ENDPOINT, { password, comment })
        .then((response) => response.data);
    },
    logout: () => {
      return axios
        .post(endpoints.LOGOUT_ENDPOINT)
        .then((response) => response.data);
    },
    totp: ({
      email,
      password,
      token,
    }: {
      email: string;
      password: string;
      token: string;
    }): Promise<AuthenticationResponseData> => {
      return axios
        .post(endpoints.TOTP_ENDPOINT, { email, password, token })
        .then((response) => response.data);
    },
    setupTotp: ({
      password,
    }: {
      password: string;
    }): Promise<SetupTOTPResponseData> => {
      return axios
        .post(endpoints.SETUP_TOTP_ENDPOINT, { password })
        .then((response) => response.data);
    },
    disableTotp: ({ password }: { password: string }) => {
      return axios
        .post(endpoints.DISABLE_TOTP_ENDPOINT, { password })
        .then((response) => response.data);
    },
    backupCode: ({
      password,
    }: {
      password: string;
    }): Promise<BackupCodeResponseData> => {
      return axios
        .post(endpoints.BACKUPCODE_TOTP_ENDPOINT, { password })
        .then((response) => response.data);
    },
    availableAddresses: ({ requestedEmail }: { requestedEmail: string }) => {
      return axios
        .get(endpoints.AVAILABLE_ADDRESSES_ENDPOINT(requestedEmail))
        .then((response) => response.data);
    },
    changePassword: ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      return axios
        .post(endpoints.CHANGE_PASSWORD, {
          current_password: currentPassword,
          new_password: newPassword,
        })
        .then((response) => response.data);
    },
    extendUserSession: ({
      password,
    }: {
      password: string;
    }): Promise<ExtendUserSessionResponseData> => {
      return axios
        .post(endpoints.EXTEND_SESSION, { password })
        .then((response) => response.data);
    },
    setSessionDuration: async ({
      password,
      session_duration,
    }: {
      password: string;
      session_duration: number;
    }): Promise<SetSessionDurationResponseData> => {
      return axios
        .post(endpoints.SET_SESSION_DURATION, { password, session_duration })
        .then((response) => response.data);
    },
    passwordStrength: ({
      password,
      user_inputs,
    }: {
      password: string;
      user_inputs: string[];
    }): Promise<PasswordStrengthResponseData> => {
      return axios
        .post(endpoints.PASSWORD_STRENGTH_ENDPOINT, { password, user_inputs })
        .then((response) => response.data);
    },
  },
  quota: {
    get: (): Promise<Quota> => {
      return axios
        .get(endpoints.QUOTA_ENDPOINT)
        .then((response) => response.data);
    },
  },
  settings: {
    update: ({
      update,
    }: {
      update: Partial<Preferences>;
    }): Promise<Preferences> => {
      return axios
        .patch(endpoints.PREFERENCES_ENDPOINT, update)
        .then((response) => response.data);
    },
    get: (): Promise<Preferences> => {
      return axios
        .get(endpoints.PREFERENCES_ENDPOINT)
        .then((response) => response.data);
    },
  },
  signup: {
    post: ({
      displayName,
      requestedEmail,
      password,
      locale,
      hostedPageId,
      couponCode,
      tfClickId,
      attribution,
      recoveryCode,
      verificationSessionToken,
    }: SignupRequest): Promise<AuthenticationResponseData> => {
      return axios
        .post(endpoints.SIGNUP_ENDPOINT_V2, {
          display_name: displayName,
          requested_email: requestedEmail,
          password,
          locale,
          recovery_code: recoveryCode,
          hosted_page_id: hostedPageId,
          coupon_code: couponCode,
          tf_click_id: tfClickId,
          attribution,
          verification_session_token: verificationSessionToken,
        })
        .then((response) => response.data);
    },
    recoveryCode: (username: string): Promise<SignedData> => {
      return axios
        .post(endpoints.SIGNUP_RECOVERY_CODE, {
          username: username,
        })
        .then((response) => response.data);
    },
    feedback: ({
      source,
      details,
      language,
    }: {
      source: string;
      details: string;
      language: string;
    }) => {
      return axios
        .post(endpoints.SIGNUP_FEEDBACK, { source, details, language })
        .then((response) => response.data);
    },
  },
  statusUpdate: {
    get: (): Promise<StatusUpdate> => {
      return axios
        .get(endpoints.STATUS_UPDATE)
        .then((response) => response.data);
    },
  },
  domainVerification: {
    createSession: (domain: string): Promise<DomainVerificationSession> => {
      return axios
        .post(endpoints.SIGNUP_DOMAIN_VERIFICATION, { domain })
        .then((response) => response.data);
    },
    getStatus: (token: string): Promise<DomainStatus> => {
      return axios
        .get(`${endpoints.SIGNUP_DOMAIN_VERIFICATION}/${token}`)
        .then((response) => response.data);
    },
    deleteSession: (token: string): Promise<DomainStatus> => {
      return axios
        .delete(`${endpoints.SIGNUP_DOMAIN_VERIFICATION}/${token}`)
        .then((response) => response.data);
    },
  },
  keys: {
    get: ({
      query,
    }: {
      query?: string;
      offset: number;
      limit: number;
    }): Promise<PGPKeysResponseData> => {
      return axios
        .get(endpoints.KEYS_ENDPOINT, { params: { query } })
        .then((response) => response.data);
    },
    getKeyDetails: ({
      fingerprint,
    }: {
      fingerprint: string;
    }): Promise<PGPKey> => {
      return axios
        .get(endpoints.KEY_ENDPOINT(fingerprint))
        .then((response) => response.data);
    },
    downloadPublic: ({ fingerprint }: { fingerprint: string }) => {
      return axios
        .get(endpoints.DOWNLOAD_PUBLIC_KEY_ENDPOINT(fingerprint))
        .then((response) => response.data);
    },
    downloadSecret: ({
      fingerprint,
      pgpPassphrase,
    }: {
      fingerprint: string;
      pgpPassphrase: string;
    }) => {
      return axios
        .post(endpoints.DOWNLOAD_SECRET_KEY_ENDPOINT(fingerprint), {
          pgpPassphrase,
        })
        .then((response) => response.data);
    },
    delete: ({ fingerprint }: { fingerprint: string }) => {
      return axios.delete(endpoints.KEY_ENDPOINT(fingerprint));
    },
    import: ({
      files,
      keyData,
    }: {
      files: File[];
      keyData: string;
    }): Promise<ImportPGPKeysResponseData> => {
      const data = new FormData();
      if (files.length) {
        files.forEach((file) => {
          data.append('files', file, file.name);
        });
      } else {
        data.append('files', '');
      }

      data.append('key_data', keyData);

      return axios
        .post(endpoints.KEYS_ENDPOINT, data)
        .then((response) => response.data);
    },
    changePassphrase: ({
      fingerprint,
      currentPassphrase,
      newPassphrase,
    }: {
      fingerprint: string;
      currentPassphrase: string;
      newPassphrase: string;
    }) =>
      axios
        .post(endpoints.KEY_CHANGE_PASSPHRASE(fingerprint), {
          current_passphrase: currentPassphrase,
          new_passphrase: newPassphrase,
        })
        .then((response) => response.data),
    refresh: ({ password }: { password: string }) =>
      axios.post(endpoints.KEY_ENDPOINT_REFRESH, { password }),
  },
  recipients: {
    suggest: ({
      query,
    }: {
      query: string;
    }): Promise<ContactSuggestionItem[]> => {
      return axios
        .get(endpoints.RECIPIENTS_SUGGEST_V2_ENDPOINT, {
          params: {
            query,
          },
        })
        .then((response) => response.data);
    },
  },
  features: {
    get: ({ flags }: { flags: string[] }): Promise<Feature[]> =>
      axios
        .get(endpoints.FEATURES_ENDPOINT, {
          params: { features: flags },
          paramsSerializer: { indexes: null },
        })
        .then((response) => response.data),
  },
  chargebee: {
    getDetails() {
      return axios
        .get<ChargebeeDetails>(endpoints.CHARGEBEE_ENDPOINT)
        .then((response) => response.data);
    },
    listInvoices(offset: string | undefined = undefined) {
      return axios
        .post<ChargebeeInvoices>(endpoints.CHARGEBEE_INVOICES, { offset })
        .then((response) => response.data);
    },
    endTrial(body: { password: string }) {
      return axios.post(endpoints.CHARGEBEE_END_TRIAL_ENDPOINT, body);
    },
    extendTrial() {
      return axios.post(endpoints.CHARGEBEE_EXTEND_TRIAL_ENDPOINT);
    },
    checkout: () =>
      axios
        .post(endpoints.CHARGEBEE_CHECKOUT_ENDPOINT)
        .then((response) => response.data),
    checkoutTrial: (body: {
      billing_period: string;
      captchaToken: string | null;
      deal: string;
      locale: string;
      plan_type: string;
    }) => {
      return axios
        .post(endpoints.CHARGEBEE_CHECKOUT_TRIAL_ENDPOINT, body)
        .then((response) => response.data);
    },
    managePaymentSources: () =>
      axios
        .post(endpoints.CHARGEBEE_MANAGE_PAYMENT_SOURCES)
        .then((response) => response.data),
    portalSession: () =>
      axios
        .post(endpoints.CHARGEBEE_PORTAL_SESSION_ENDPOINT)
        .then((response) => response.data),
    syncSubscription: () =>
      axios
        .post(endpoints.CHARGEBEE_SYNC_SUBSCRIPTION_ENDPOINT)
        .then((response) => response.data),
    getInvoiceDetails: (): Promise<InvoiceDetails> => {
      return axios
        .get(endpoints.CHARGEBEE_INVOICE_DETAILS_ENDPOINT)
        .then((response) => response.data);
    },
    updateInvoiceDetails: (update: InvoiceDetails): Promise<InvoiceDetails> => {
      return axios
        .put(endpoints.CHARGEBEE_INVOICE_DETAILS_ENDPOINT, update)
        .then((response) => response.data);
    },
    userCurrency: (): Promise<UserCurrency> => {
      return axios
        .get(endpoints.CHARGEBEE_CURRENCY)
        .then((response) => response.data);
    },
    getChargebeePlan: (): Promise<ChargebeePlan> =>
      axios.get(endpoints.CHARGEBEE_PLAN).then((response) => response.data),
    async changePlan(body: {
      password: string;
      plan_id: string;
    }): Promise<void> {
      await axios.post(endpoints.CHARGEBEE_PLAN, body);
    },
    estimate: async (plan_id: string): Promise<ChargebeeEstimate> => {
      const { data } = await axios.post(endpoints.CHARGEBEE_ESTIMATE, {
        plan_id,
      });
      return data;
    },
    cancelChargebeeSubscription: (
      password: string,
      comment: string
    ): Promise<void> =>
      axios.post(endpoints.CHARGEBEE_CANCEL_SUBSCRIPTION, {
        password,
        comment,
      }),
    reactivateSubscription: (): Promise<void> =>
      axios.post(endpoints.CHARGEBEE_REACTIVATE_SUBSCRIPTION),
    takeCancellationDeal: (deal: CancellationDeal): Promise<void> =>
      axios.post(endpoints.CHARGEBEE_CANCEL_SUBSCRIPTION_DEAL, {
        type: deal.type,
      }),
    getCancellationDealAvailability:
      async (): Promise<CancellationDeal | null> => {
        try {
          const { data: deal } = await axios.get(
            endpoints.CHARGEBEE_CANCEL_SUBSCRIPTION_DEAL
          );
          return deal;
        } catch (error) {
          if (
            error instanceof axios.AxiosError &&
            error.response &&
            error.response.status === 404
          ) {
            return null; // No deal available
          }
          throw error;
        }
      },
  },
  coupons: {
    getCouponInformation: (
      coupon_code: string
    ): Promise<CouponCodeInformation> => {
      return axios
        .get(endpoints.COUPONS_ENDPOINT(coupon_code))
        .then((response) => response.data);
    },
  },
  browsermetrics: {
    post: (metrics: {
      frontend_version: string;
      resources: {
        endpoint: string;
        domainLookupStart: number;
        domainLookupEnd: number;
        connectStart: number;
        secureConnectionStart: number;
        connectEnd: number;
        requestStart: number;
        responseStart: number;
        responseEnd: number;
        transferSize: number;
        duration: number;
        fetchStart: number;
      }[];
    }) =>
      axios
        .post(endpoints.BROWSER_METRICS_ENDPOINT, metrics)
        .then((response) => response.data)
        .catch(() => {}),
  },
  uiEvents: {
    create: ({ event_type }: { event_type: string }) => {
      return axios
        .post(endpoints.UI_EVENTS_ENDPOINT, { event_type })
        .then((response) => response.data);
    },
  },
  aliases: {
    get: async (): Promise<AliasesResponseData> => {
      const {
        data: { aliases, alias_domains },
      } = await axios.get(endpoints.ALIASES_ENDPOINT);
      return {
        aliases: aliases.map((alias: any) => {
          const { display_name, ...rest } = alias;
          return { displayName: display_name, ...rest };
        }),
        alias_domains,
      };
    },
    create: async (alias: CreateAliasRequest): Promise<Alias> => {
      const { data } = await axios.post(endpoints.ALIASES_ENDPOINT, alias);
      return data;
    },
    createBurner: async (): Promise<Alias> => {
      const { data } = await axios.post(endpoints.ALIASES_ENDPOINT, {
        type: 'burner',
      });
      return data;
    },
    delete: async (id: string) => {
      const { data } = await axios.delete(endpoints.ALIAS_ENDPOINT(id));
      return data;
    },
    update: async ({
      id,
      update,
    }: {
      id: string;
      update: UpdateAliasRequest;
    }): Promise<Alias> => {
      const { data } = await axios.patch(endpoints.ALIAS_ENDPOINT(id), update);
      return data;
    },
  },
  filters: {
    get: (): Promise<Filters> =>
      axios.get(endpoints.FILTERS_ENDPOINT).then((response) => response.data),
    update: ({ filters, entity_tag }: Filters): Promise<Filters> =>
      axios
        .post(endpoints.FILTERS_ENDPOINT, { filters, entity_tag })
        .then((response) => response.data),
  },
  passwordProtectedMessage: {
    decrypt: ({
      id,
      password,
      salt,
    }: {
      id: string;
      password: string;
      salt: string;
    }): Promise<PasswordProtectedMessage> =>
      axios
        .post(endpoints.PASSWORD_PROTECTED_MESSAGE_ENDPOINT(id), {
          password,
          salt,
        })
        .then((response) => response.data),
  },
  imapTokens: {
    list: (): Promise<ImapToken[]> =>
      axios
        .get(endpoints.IMAP_TOKENS_ENDPOINT)
        .then((response) => response.data),
    create: ({
      identifier,
      password,
    }: {
      identifier: string;
      password: string;
    }): Promise<ImapToken> =>
      axios
        .post(endpoints.IMAP_TOKENS_ENDPOINT, { identifier, password })
        .then((response) => response.data),
    delete: ({
      identifier,
      password,
    }: {
      identifier: string;
      password: string;
    }) =>
      axios
        .delete(endpoints.IMAP_TOKENS_ENDPOINT, {
          data: { identifier, password },
        })
        .then((response) => response.data),
  },
  recovery: {
    getRecoveryStatus: (): Promise<RecoveryStatusResponseData> => {
      return axios
        .get(endpoints.RECOVERY_STATUS)
        .then((response) => response.data);
    },
    enableRecoveryAddress: ({
      recoveryAddress,
      password,
    }: {
      recoveryAddress: string;
      password: string;
    }) => {
      return axios
        .post(endpoints.ENABLE_RECOVERY_ADDRESS, {
          recovery_address: recoveryAddress,
          password,
        })
        .then((response) => response.data);
    },
    disableRecoveryAddress: ({ password }: { password: string }) => {
      return axios
        .post(endpoints.DISABLE_RECOVERY_ADDRESS, { password })
        .then((response) => response.data);
    },
    confirmRecoveryAddress: ({
      confirmationToken,
      password,
    }: {
      confirmationToken: string;
      password: string;
    }) => {
      return axios
        .post(endpoints.CONFIRM_RECOVERY_ADDRESS, {
          confirmation_token: confirmationToken,
          password,
        })
        .then((response) => response.data);
    },
    enableRecoveryKey: ({
      password,
    }: {
      password: string;
    }): Promise<EnableRecoveryKeyResponseData> => {
      return axios
        .post(endpoints.ENABLE_RECOVERY_KEY, { password })
        .then((response) => response.data);
    },
    disableRecoveryKey: ({ password }: { password: string }) => {
      return axios
        .post(endpoints.DISABLE_RECOVERY_KEY, { password })
        .then((response) => response.data);
    },
    requestRecoveryEmail: ({
      email,
      captchaToken,
    }: {
      email: string;
      captchaToken: string;
    }): Promise<void> => {
      return axios
        .post(endpoints.REQUEST_RECOVERY_EMAIL, {
          email,
          captcha_token: captchaToken,
        })
        .then((response) => response.data);
    },
    resetPassword: ({
      email,
      recoveryCode,
      new_password,
      captchaToken,
    }: {
      email: string;
      recoveryCode: string;
      new_password: string;
      captchaToken: string;
    }): Promise<ResetPasswordResponseData> => {
      return axios
        .post(endpoints.RESET_PASSWORD, {
          email,
          new_password,
          recovery_code: recoveryCode,
          captcha_token: captchaToken,
        })
        .then((response) => response.data);
    },
  },
  newMailNotifications: {
    get: (): Promise<NewMailNotificationsResponseData> => {
      return axios
        .get(endpoints.NEW_MAIL_NOTIFICATIONS)
        .then((response) => response.data);
    },
    set: (sendNewMailNotifications: boolean): Promise<void> => {
      return axios
        .put(endpoints.NEW_MAIL_NOTIFICATIONS, {
          enabled: sendNewMailNotifications,
        })
        .then((response) => response.data);
    },
  },
  customDomains: {
    all: (): Promise<Domain[]> => {
      return axios
        .get(endpoints.DOMAINS_ENDPOINT)
        .then((response) => response.data);
    },
    get: (customDomainName: string): Promise<DomainStatus> => {
      return axios
        .get(endpoints.DOMAIN_ENDPOINT(customDomainName))
        .then((response) => response.data);
    },
    delete: ({
      domainName,
      password,
    }: {
      domainName: string;
      password: string;
    }) => {
      return axios.delete(endpoints.DOMAIN_ENDPOINT(domainName), {
        headers: { 'x-startmail-password': password },
      });
    },
    create: async (domain_name: string) => {
      return await axios.post(endpoints.DOMAINS_ENDPOINT, { domain_name });
    },
  },
  recentRecipients: {
    get: (): Promise<RecentRecipient[]> => {
      return axios
        .get(endpoints.RECENT_RECIPIENTS)
        .then((response) => response.data);
    },
    delete: async (): Promise<void> => {
      await axios.delete(endpoints.RECENT_RECIPIENTS);
    },
    deleteOne: async (recipientId: number): Promise<void> => {
      await axios.delete(endpoints.RECENT_RECIPIENT(`${recipientId}`));
    },
  },
  migrationStatus: {
    get: (): Promise<MigrationStatus> => {
      return axios
        .get(endpoints.MIGRATION_STATUS)
        .then((response) => response.data);
    },
  },
  groupSubscriptions: {
    getChargebeeProratedPricing: (): Promise<ChargebeeProratedPricing> => {
      return axios
        .get(endpoints.CHARGEBEE_PRORATED_PRICING)
        .then((response) => response.data);
    },
    getGroupSubscriptions: (): Promise<GroupSubscription> => {
      return axios
        .get(endpoints.GROUP_SUBSCRIPTIONS)
        .then((response) => response.data);
    },
    createAccount: ({
      password,
      signup,
    }: {
      password: string;
      signup: {
        display_name: string;
        requested_email: string;
        password: string;
      };
    }): Promise<void> => {
      return axios
        .post(endpoints.GROUP_SUBSCRIPTIONS, { password, signup })
        .then((response) => response.data);
    },
    deleteGroupSubscription: (groupSubscriptionDeletionData: {
      password: string;
      terminate: {
        username: string;
      };
    }): Promise<void> => {
      return axios
        .delete(endpoints.GROUP_SUBSCRIPTIONS, {
          data: groupSubscriptionDeletionData,
        })
        .then((response) => response.data);
    },
    patchAccount: ({
      change,
      password,
    }: {
      change: {
        is_manager: boolean;
        username: string;
      };
      password: string;
    }): Promise<void> => {
      return axios.patch(endpoints.GROUP_SUBSCRIPTIONS, { change, password });
    },
  },
  unsubscribe: {
    get: ({ token }: Unsubscribe): Promise<UnsubscribeDetails> => {
      return axios
        .get(endpoints.UNSUBSCRIBE(token))
        .then((response) => response.data);
    },
    post: ({ token }: Unsubscribe): Promise<void> => {
      return axios
        .post(endpoints.UNSUBSCRIBE(token))
        .then((response) => response.data);
    },
  },
};

axios.defaults.headers['X-Requested-With'] =
  process.env.VUE_APP_VERSION || 'XMLHttpRequest';

axios.defaults.paramsSerializer = {
  indexes: false,
};

export function getCookie(name: string) {
  const parts = document.cookie.split(';');
  const cookie = parts.find((part) => part.trim().startsWith(name + '='));
  if (cookie) {
    return cookie.trim().substring(name.length + 1);
  }
}

export function setCookie(
  cookieName: string,
  cookieValue: string,
  expHours: number,
  domain?: string
) {
  const d = new Date();
  d.setTime(d.getTime() + expHours * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie =
    cookieName +
    '=' +
    cookieValue +
    ';' +
    expires +
    (domain ? ';domain=' + domain : '') +
    ';path=/';
}

export function deleteCookie(cookieName: string) {
  document.cookie =
    cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
